// custom variables and override bootstrap variables

// colors

$white: #fff;
$black: #000;
$green: #008c43;
$olive: #82bf39;
$olive-dark: #618f29;
$pink: #bd3782;
$purple: #7a559f;
$blue: #00a4ed;
$gray-light: #c0c0c0;

// body styles

$body-bg: $white;
$text-color: #535353;
$font-size-base: 20px;
$line-height-base: 1.7;
$font-family-sans-serif: "Poppins", Helvetica, Arial, sans-serif;

// tables

$table-bg-accent: #f8ebf2;
$table-cell-padding: 1px 10px;